import { Platform } from "react-native";
import { flow, Instance, SnapshotIn, SnapshotOut, types } from "mobx-state-tree"
import { values } from "mobx";
import * as FileSystem from "expo-file-system";
import { withSetPropAction } from "./helpers/withSetPropAction"
import { withStatusModel } from "./helpers/withStatusModel";
import { itemModel, AnyJsonValue, phoneInformationModel } from "./helpers/typesModels";
import DeviceInfo from "../services/deviceInfo"
import { FORMPAGE_TYPE, PAYLOAD_TYPE, ITEM_STATUS, FORM_TYPE } from "../voolks/types"
import { customApp } from "../config";
import AsyncStorage from "@react-native-async-storage/async-storage";
import OldStore from "../utils/storage/oldStore";
import * as storage from "../utils/storage";

/**
 * Model description here for TypeScript hints.
 */


const _uploadFiles = async (store, item_payload: any[], deptId: string, formId: string) => {
  let lstPromises = [];
  const totalIntentos = 3
  for (let fieldId in item_payload) {
    if (["photo", "sign", "video", 'docs'].includes(item_payload[fieldId].type) && item_payload[fieldId].value) {
      let values = item_payload[fieldId]?.value;
      if (!Array.isArray(values))
        values = [values];
      for (let i in values) {
        let fileFullname: string = values[i].uri || values[i];
        if (fileFullname.substring(0, 4) != "http") {
          let fileName: string = fileFullname.substring(fileFullname.lastIndexOf("/") + 1);
          let shortFileName: string = fileName.substring(fileName.indexOf("-") + 1);
          let stat = Platform.OS == "web" ? { size: 0 } : await FileSystem.getInfoAsync(fileFullname);
          const loadInfo3 = store.addLoadingInfo({ title: shortFileName.concat(" ", (stat.size / 1024 / 1024).toFixed(2), "MB"), spinner: true })
          let promise = new Promise(async (resolve, reject) => {
            for (let intento = 1; intento <= totalIntentos; intento++) {
              try {
                store.updLoadingInfo({ id: loadInfo3, title: shortFileName.concat(" ", (stat.size / 1024 / 1024).toFixed(2), "MB").concat(" Intento ", intento.toString()), spinner: true })
                let resp = await store.api().uploadFile(fileName, fileFullname);
                if (resp.status != 200)
                  throw (new Error("No se puedo guardar el archivo en el servidor"));
                let respBody = await resp.json();
                let container = respBody.result.files[fileName][0].container
                let uriSaved = await store.api().urlDownloadFile(fileName, container);
                store.updLoadingInfo({ id: loadInfo3, spinner: false })
                if (item_payload[fieldId].type == "sign")
                  item_payload[fieldId].value = uriSaved
                else
                  item_payload[fieldId].value[i] = { uri: uriSaved }
                intento = totalIntentos + 1;
                item_payload[fieldId].value[i] = { uri: uriSaved };
                resolve(intento);
              } catch (error) {
                error.message = `MessageError: ${error.message}\nResponse.body: ${"respBody"}`;
                if (intento >= totalIntentos || error.message == "Sin red de datos") {
                  intento = totalIntentos + 1;
                }
              }
            }
          });
          lstPromises.push(promise);
          if (lstPromises.length == 5) {
            await Promise.all(lstPromises).then(values => {
              console.log("Promises finish", values);
              return true;
            }).catch(reason => {
              console.log(reason);
              throw new Error(`Error subiendo archivos`);
            });
            lstPromises = [];
          }
        }
      }
    } else if (item_payload[fieldId].type == "simplelist") {
      for (let rec in item_payload[fieldId].value) {//registros de la lista
        await _uploadFiles(store, item_payload[fieldId].value[rec], deptId, formId);
      }
    }
  }
  await Promise.all(lstPromises).then(values => {
    return true;
  }).catch(reason => {
    console.log(reason);
    throw new Error(`Error subiendo archivos`);
  });
}


export const ItemsStoreModel = types
  .model("ItemsStore")
  .props({
    itemsByUsersDB: types.array(itemModel),
    itemActive: types.maybeNull(itemModel),
    itemActiveIDX: types.maybeNull(types.number), //-1 nuevo, undefined ninguno activo
    demo: types.maybeNull(AnyJsonValue),
  })
  .actions(withSetPropAction)
  .extend(withStatusModel)
  .views((store) => ({
    myItems: ({ deptId = undefined, formId = undefined } = {}) => {
      let myItems = values(store.itemsByUsersDB)
        .filter(item => (
          //@ts-ignore
          (item.memberId == store.parent.authenticationStore.userIdLogged || item.membersId?.includes(store.parent.authenticationStore.userIdLogged))
          && (deptId == undefined || item?.deptId == deptId)
          && (formId == undefined || item?.formId == formId)
        ));
      return myItems || [];
    },
    /* myPTWs: ({ deptId = undefined, formId = undefined } = {}) => {
      let myItems = values(store.itemsByUsersDB);
      return myItems || [];
    }, */
    getIDXofItem: (itemSelected: any): number => {
      let idx: number = values(store.itemsByUsersDB).findIndex((item: any) => {
        return new Date(item._startedAt).getTime() == new Date(itemSelected._startedAt).getTime()
      });
      return idx;
    },
    getItemByStartedAt: (startedAt): number => {
      let item: any = values(store.itemsByUsersDB).find((item: any) => {
        return new Date(item._startedAt).getTime() == startedAt
      });
      return item;
    },
    getPayloadAsArray: () => {
      let payloadArray = [];
      for (let _key of store.itemActive.payload.keys()) {
        payloadArray.push({ ...store.itemActive.payload.get(_key), id: _key });
      }
      return payloadArray;
    },
    getPayloadElementByid: (fieldId: string) => {
      return store.itemActive.payload.get(fieldId)
    },
    getPayloadElement: (fieldId: string, parentId: string | undefined, recordIdx: number | undefined) => {
      try {
        if (parentId == undefined)
          // @ts-ignore
          return store.itemActive.payload.get(fieldId).toJSON();
        if (recordIdx != undefined && store.itemActive.payload.get(parentId).value[recordIdx].has(fieldId)) {
          return JSON.parse(JSON.stringify(store.itemActive.payload.get(parentId).value[recordIdx].get(fieldId)));
        }
        else if (store.itemActive.payload.get(parentId).activeRecord?.record?.get(fieldId) != undefined)
          // @ts-ignore
          return store.itemActive.payload.get(parentId).activeRecord?.record?.get(fieldId).toJSON();
        else
          // @ts-ignore
          return store.itemActive.payload.get(fieldId).toJSON();
      } catch (e) {
        return { valid: null, value: null }
      }

    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((store) => ({
    getErrorElement: (element: any, payloadValue: any) => {
      let err: string[] = []
      if (!element) {
        return { valid: false, errorList: ["Elemento no definido"] };
      }
      if (element.type == "autonumeric")
        return { valid: true, errorList: [] }
      if (element.type == "simplelist") {
        payloadValue = Array.isArray(payloadValue) ? payloadValue : [];
        if (element.validation?.required && payloadValue.length > 0 && !payloadValue.reduce((ret, rec) => ret ? Object.values(rec).find((field: any) => field.valid != true && field.inactiveGroup != true) == undefined : ret, true)) {
          err.push("Este campo es requerido. Todos los registros deben ser válidos.");
        }
        if (element.validation?.recordCountRequired > 0 && payloadValue.length < element.validation?.recordCountRequired) {
          err.push(`Minimo de Registros requeridos: ${element.validation.recordCountRequired}`);
        }
        return { valid: err.length == 0, errorList: err };;
      }

      if (element.validation?.required && (payloadValue == null || payloadValue?.length == 0)) {
        err.push("Este campo es requerido");
      }

      if (element.type == "number") {
        if (payloadValue && element.templateData.minimum
          && element.templateData.minimum > Number(payloadValue))
          err.push(`No puede ser inferior al limite mínimo: ${element.templateData.minimum}`);
        if (payloadValue && element.templateData.maximum
          && element.templateData.maximum < Number(payloadValue))
          err.push(`No puede ser superior al limite máximo: ${element.templateData.maximum}`);
      }
      if ((element.type == "date" || element.type == "time") && !(payloadValue == "" || payloadValue == null)) {
        let today = new Date(store.itemActive._startedAt);//Esto es si por se calculo dias hacia atras y no se cerro
        let todayFuture = new Date();
        let min;
        let max;
        if (element.type == "time") {
          let allowPrevious = element.templateData.allow_old_times || element.validation.allowPrevious;
          min = allowPrevious ?
            ((element.validation.maxPrevious || 0) == 0 ? undefined
              : today.setHours(today.getHours() - element.validation.maxPrevious))
            : today;
        } else {
          let allowPrevious = element.templateData.allow_old_dates || element.validation.allowPrevious;
          min = allowPrevious ?
            ((element.validation.maxPrevious || 0) == 0 ? undefined
              : new Date(today.setDate(today.getDate() - element.validation.maxPrevious)).getYYYYMMdd())
            : today.getYYYYMMdd();
          max = element.validation.allowFuture ?
            ((element.validation.maxFuture || 0) == 0 ? undefined
              : new Date(todayFuture.setDate(todayFuture.getDate() + element.validation.maxFuture)).getYYYYMMdd())
            : todayFuture.getYYYYMMdd();

          if (min?.length > 0)
            min = min.concat("T00:00:00");

          if (max?.length > 0)
            max = max.concat("T23:59:59")
        }
        console.log("getErrorElement", payloadValue, min, max)
        if (min && new Date(min) > new Date(payloadValue)) {
          err.push("Error fuera del rango configurado. Debe ser mayor de ".concat(new Date(min).toLocaleDateString()));
        }
        if (max && new Date(max) < new Date(payloadValue)) {
          err.push("Error fuera del rango configurado. Debe ser menor a ".concat(new Date(max).toLocaleDateString()));
        }
      }

      if (element.type == "sendemail" && payloadValue) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emails = payloadValue.toString() + ","
        emails.split(",").forEach(email => {
          let _email = email.split("|")[0]
          if (_email.trim() != "" && !re.test(String(_email).toLowerCase()))
            err.push("Error email inválido:" + email);
        });
      }
      return { valid: err.length == 0, errorList: err };
    },
  }))
  .actions((store) => ({
    getFormActive: () => {
      if (store.itemActive) {
        return store.parent.formsStore.mySubscriptions().subscriptions.find((item) => item.id == store.itemActive.deptId).forms.find(f => f.id == store.itemActive.formId);
      }
      return {}
    },

    new: flow(function* newItem(form: FORM_TYPE, taskId?: string, fromItem?: any) {
      function findField(form: FORM_TYPE, fieldId: string) {
        function _findField(lstFields) {
          return lstFields.reduce(function (obj, el) {
            if (el.id == fieldId) return el;
            if (obj == undefined && el.nodes) obj = _findField(el.nodes);
            return obj;
          }, undefined);
        }
        return form.pages.reduce(function (obj, page) {
          if (obj) return obj;
          return _findField(page.fields);
        }, undefined);
      };
      function purgePayload(form: FORM_TYPE, fields: Object): any {
        let _fields: any[], _fieldsId: string[];
        let _fieldsIdOtherStep: string[] = [];
        if (!Array.isArray(fields)) {
          _fields = Object.values(fields);
          _fieldsId = Object.keys(fields);
        }
        else
          _fields = fields;
        _fields.forEach((f, idx) => {
          const fieldId = f.id || _fieldsId[idx];
          const field = findField(form, fieldId);
          if ((form.type == "workflow" || form.type == "ptw")) {
            if (f.type == "group") {
              if (field && field.requires.some(r => form.workflow.steps.some(s => s.checkStepON == r.elementId)))
                _fieldsIdOtherStep = _fieldsIdOtherStep.concat(field.nodes.map(f => f.id));
            }
            if (f.type == "checkbox") {
              _fieldsIdOtherStep.push(fieldId);
            }
          }
          if(field)
            f.readOnly = field.templateData?.readOnly || false;
          if (_fieldsIdOtherStep.includes(fieldId)) {
            f.value = null;
            f.valid = null;
          } else if (f.type == "sign" || f.type=="timestamp") {
            f.value = null;
            f.valid = null;
          } else if (field && field.type == "autonumeric") {
            f.value = null;
            f.valid = true;
          } else if (f.type == "simplelist" && f.value) {
            f.valid = null;
            f.value.forEach((r: any) => purgePayload(form, r))
          }
        })
        return fields;

      }
      let deptId: string = form.departmentId;
      let formId: string = form.id;
      let formType: string = form.type;
      console.log("itemsStore", "new", 1);
      store.setProp("itemActiveIDX", -1);
      store.setProp("itemActive", {
        deptId, formId, taskId,
        _startedAt: new Date(),
        _timeOffset: new Date().getTimezoneOffset(),
        memberId: store.parent.authenticationStore.userIdLogged,
        tenantId: store.parent.authenticationStore.userInfo?.user.tenantId,
        formType: formType,
        payload: fromItem ? purgePayload(form, JSON.parse(JSON.stringify(fromItem.payload))) : null,
        status: "draft",
        _rnd: "".concat(new Date().getTime().toString(), ".", Math.floor(Math.random() * 1000000).toString()),
        phoneInformation: phoneInformationModel.create({
          deviceId: store.parent.authenticationStore.userInfo.deviceId,
          model: store.parent.authenticationStore.userInfo.model,
          systemVersion: store.parent.authenticationStore.userInfo.systemVersion,
          package: customApp.packagename,
          version: customApp.version,
          versionCode: customApp.versionCode
        })
      });
      console.log("itemsStore", "new", 2);
      return store.itemActive;

    }),
    makePayloadFromFormPages: flow(function* makePayloadFromFormPages(pages: FORMPAGE_TYPE[], preValues = {}) {

      const walkFieldToPayload = (fields: any, fieldsInfo: any, parentType: string = "form", preValues) => {
        fields.forEach((f: any) => {
          if (parentType != "simplelist") {
            if (store.itemActive.payload && store.itemActive.payload.get(f.id) != undefined)
              fieldsInfo.payload[f.id] = {
                ...store.itemActive.payload.get(f.id),
                type: f.type
              };
            else
              fieldsInfo.payload[f.id] = {
                value: preValues[f.id],
                valid: store.getErrorElement(f, preValues[f.id]).valid,
                type: f.type,
                inactiveGroup: parentType == "group"

              };
          }
          if (f.type != "simplelist" && f.nodes && f.nodes.length > 0) {
            walkFieldToPayload(f.nodes, fieldsInfo, parentType == "simplelist" ? parentType : f.type, preValues)
          }

        });
      }
      if (store.itemActiveIDX == undefined)
        return;
      let fieldsInfo = pages.reduce((fieldsInfo: { elements: any, payload: any }, page: FORMPAGE_TYPE, idx) => {
        walkFieldToPayload(page.fields, fieldsInfo, undefined, preValues);
        return fieldsInfo
      }, { elements: {}, payload: {} })
      store.itemActive.payload = { ...fieldsInfo.payload };
    }),
    removeAll: flow(function* removeAll() {
      store.itemsByUsersDB.splice(0);
    }),
    removeItem: flow(function* removeItem(idx: number) {
      store.itemsByUsersDB.splice(idx, 1);
    }),
    saveItemActive: (itemStatus: (keyof typeof ITEM_STATUS) = undefined, readOnly: boolean = false) => {
      if (store.itemActiveIDX == undefined)
        return false;
      if (itemStatus) {
        store.itemActive.status = itemStatus
        if (itemStatus == "closed") {
          store.itemActive.readOnly = readOnly;
          store.itemActive.closedAt = new Date();
          store.itemActive.phoneInformation = phoneInformationModel.create({
            deviceId: store.parent.authenticationStore.userInfo.deviceId,
            model: store.parent.authenticationStore.userInfo.model,
            systemVersion: store.parent.authenticationStore.userInfo.systemVersion,
            package: customApp.packagename,
            version: customApp.version,
            versionCode: customApp.versionCode
          })

        }
      }
      if (store.itemActiveIDX == -1)
        //@ts-ignore
        store.itemActiveIDX = (store.itemsByUsersDB.push(store.itemActive.toJSON()) - 1);
      else
        //@ts-ignore
        store.itemsByUsersDB[store.itemActiveIDX] = store.itemActive.toJSON();

      store.setProp("itemActiveIDX", store.itemActiveIDX);
      //@ts-ignore
      store.setProp("itemsByUsersDB", store.itemsByUsersDB.toJSON());
      return true;
    },
    insertItem: flow(function* insertItem(itemJSON) {
      itemJSON.phoneInformation = itemJSON.phoneInformation ?? phoneInformationModel.create({
        deviceId: DeviceInfo.deviceId,
        model: DeviceInfo.deviceModel,
        systemVersion: DeviceInfo.systemVersion,
        package: customApp.packagename,
        version: customApp.version,
        versionCode: customApp.versionCode
      });
      store.itemsByUsersDB.push(itemJSON);
    }),
    activateItem: (itemSelected: any) => {
      let idx: any = store.itemsByUsersDB.findIndex((item: any) => {
        return new Date(item._startedAt).getTime() == new Date(itemSelected._startedAt).getTime()
      });
      //@ts-ignore
      let _itemActive = { ...store.itemsByUsersDB[idx].toJSON() }
      store.setProp("itemActiveIDX", idx);
      //@ts-ignore
      store.setProp("itemActive", _itemActive);
    }
  }))
  .actions((store) => ({
    updPayloadElement: (fieldId: string, payloadElement: PAYLOAD_TYPE, parentId: string | undefined, recordIdx: number | undefined, closeRecord: boolean = false) => {
      console.log("updPayloadElement in", fieldId, payloadElement)
      if (JSON.stringify(payloadElement) == JSON.stringify(store.getPayloadElement(fieldId, parentId)))
        return;
      if (fieldId == undefined)
        return;
      //@ts-ignore
      let itemActive = store.itemActive;
      let payloadAffected;
      if (recordIdx != undefined) {
        payloadAffected = itemActive.payload.get(parentId).value[recordIdx].get(fieldId);
        if (payloadAffected == undefined) {
          console.log("ERROR UpdPayloadElement", fieldId)
          return;
        }
        for (const key in payloadElement) {
          if (payloadAffected.has(key))
            payloadAffected.set(key, payloadElement[key]);
          else
            payloadAffected[key] = payloadElement[key];
        }
      } else {
        payloadAffected = parentId == undefined ? itemActive.payload?.get(fieldId) : itemActive.payload.get(parentId).activeRecord.record.get(fieldId);
        for (const key in payloadElement) {
          payloadAffected[key] = payloadElement[key]
        }
      }
      if (closeRecord) {
        store.saveItemActive("closed", true);
      }
    },
    updPayloadElements: flow(function* update(data: { fieldId: string, payloadElement: PAYLOAD_TYPE }[], parentId: string | undefined, recordIdx: number | undefined, closeRecord: boolean = false) {
      if (data.length == 0)
        return

      console.log("updPayloadElements in", parentId)
      //@ts-ignore
      let itemActive = store.itemActive;
      data.forEach(({ fieldId, payloadElement }) => {
        let payloadAffected;
        if (recordIdx != undefined) {
          if (!itemActive.payload.get(parentId).value[recordIdx].has(fieldId))
            itemActive.payload.get(parentId).value[recordIdx].set(fieldId, { value: null, valid: true });
          payloadAffected = itemActive.payload.get(parentId).value[recordIdx].get(fieldId);
          Object.keys(payloadElement)
            .forEach(key =>
              payloadAffected.set(key, payloadElement[key])
            );
        } else {
          payloadAffected = parentId == undefined ? itemActive.payload?.get(fieldId) : itemActive.payload.get(parentId).activeRecord.record.get(fieldId);
          if (payloadAffected)
            Object.keys(payloadElement)
              .forEach(key =>
                payloadAffected[key] = payloadElement[key]
              );
        }
      });
      closeRecord && store.saveItemActive("closed", true);
      console.log("updPayloadElements out", parentId)

    }),
    populatePayload: (data: any[], populateValues: any, parentId: string | undefined) => {
      try {
        if (data) {
          //@ts-ignore
          let itemActive = JSON.parse(JSON.stringify(store.itemActive.toJSON()));
          for (let key in populateValues) {
            let fieldId = populateValues[key];
            if (fieldId != undefined) {
              let payloadElement = parentId == undefined ? itemActive.payload[fieldId] : itemActive.payload[parentId].activeRecord.record[fieldId]; //getPayloadElement(element.integration.populateValues[key], parentId)
              payloadElement = payloadElement ?? {};
              if (payloadElement?.type == "simplelist") {
                payloadElement.value = undefined;
                if (Array.isArray(data[key])) {
                  data[key] = data[key].map(row => {
                    if (Array.isArray(row)) {
                      return row.reduce((obj, field) => { obj[field.id] = { value: field.value, valid: field.valid }; return obj; }, {});
                    } else
                      return {}
                  });
                } else
                  data[key] = [];
              }
              payloadElement.value = data[key];
              payloadElement.valid = payloadElement.type == 'autonumeric' || undefined;
            }
          }
          store.setProp("itemActive", itemActive);
        }
      } catch (e) {
        console.log("ItemsStore", "populatePayload", e)
      }
    },
    saveFilePayload: flow(function* saveFileOfPayload(fileName: string, dataUri: string) {

      try {
        fileName = `${store.itemActive.deptId}.${store.itemActive.formId}-${fileName}`;
        if (Platform.OS == "web") {
          store.setStatus("pending");
          fileName = `WEB-${fileName}`;
          let resp = yield store.api().uploadFile(fileName, dataUri)
          if (resp.status != 200)
            throw (new Error("No se puedo guardar el archivo en el servidor"));
          let respBody = yield resp.json();
          let container = respBody.result.files[fileName][0].container
          fileName = store.api().urlDownloadFile(fileName, container);
          store.setStatus("done");
        } else {
          fileName = FileSystem.documentDirectory.concat(fileName);
          if (dataUri.substring(0, 5) == "data:") {
            console.log(dataUri);
            console.log(dataUri.substring(dataUri.indexOf("base64,") + 7));
            yield FileSystem.writeAsStringAsync(
              fileName,
              dataUri.substring(dataUri.indexOf("base64,") + 7),
              { encoding: FileSystem.EncodingType.Base64 }
            )
          } else if (dataUri.includes(FileSystem.cacheDirectory)) {
            yield FileSystem.moveAsync({ from: dataUri, to: fileName });
          } else {
            yield FileSystem.copyAsync({ from: dataUri, to: fileName });
          }
        }
      } catch (e) {
        console.log(e)
      } finally {

        return { uri: fileName };
      }

    }),
  }))
  .actions((store) => ({
    upFilesOfPayload: flow(function* upFilesOfPayload(item_payload: any[], deptId: string, formId: string) {
      yield _uploadFiles(store, item_payload, deptId, formId)
    }),
  }))
  .actions((store) => ({
    upload: flow(function* upload({ deptId = undefined, formId = undefined, onlyOne = false, itemIDX = undefined }) {
      console.log("itemsStore.upload");
      const items = store.itemsByUsersDB
      let items_closed = 0;
      store.setStatus("pending");
      yield AsyncStorage.setItem('uploadItemsRunning', "1");
      const items_closed_total = items
        .filter((item: any, idx: number) => item.status === ITEM_STATUS.closed.toString()
          && (!itemIDX || idx == itemIDX)
          && (!deptId || item.deptId === deptId)
          && (!formId || item.formId === formId)
          && (customApp.isPTW || item.memberId == store.parent.authenticationStore.userIdLogged)
        )
        .length;
      const loadInfo1 = store.addLoadingInfo({ title: "Subiendo registros cerrados", spinner: true })
      store.addLoadingInfo({ title: "Total pendientes: ".concat(items_closed_total.toString()) })
      console.log("itemsStore.upload", 1);
      onlyOne == onlyOne || itemIDX > 0;
      for (let idx: number = 0; idx < items.length; idx++) {
        //@ts-ignore
        let item = { ...JSON.parse(JSON.stringify(items[idx].toJSON())), isPTW: customApp.isPTW };
        if (
          item.status === 'closed'
          && (!itemIDX || idx == itemIDX)
          && (!deptId || item.deptId === deptId)
          && (!formId || item.formId === formId)
          && (customApp.isPTW || item.memberId == store.parent.authenticationStore.userIdLogged)
        ) {
          items_closed++;
          const loadInfo2 = store.addLoadingInfo({ title: "# ".concat(items_closed.toString()), spinner: true })
          if (Platform.OS != "web")
            yield store.upFilesOfPayload(item.payload, item.deptId, item.formId);
          delete item.id;
          const response = yield store.api().uploadItem(item)
          if (response.status == 200) {
            let json = yield response.json();
            if (idx == store.itemActiveIDX) {
              store.itemActive.id = json.id;
              store.itemActive.status = "sent";
              //@ts-ignore
              store.setProp("itemActive", store.itemActive.toJSON());
            }
            for (const f in json.payload) {
              if (json.payload[f].type == "autonumeric")
                items[idx].payload.set(f, json.payload[f]);
            }
            items[idx].id = json.id;
            items[idx].status = "sent";
            //@ts-ignore
            store.setProp("itemsByUsersDB", items.toJSON());
          } if (response.status == 409) {
            //errores que avisa el backend
            let json = yield response.json();
            store.parent.statusStore.setFlashMessage("Error en Item, no cumple condiciones para ser subido: ".concat(json.error.message))
          } else {
            console.log(response.status);
          }
          idx = onlyOne ? items.length : idx;
        }
      }
      yield AsyncStorage.setItem('uploadItemsRunning', "0");
      store.setStatus("done");
    }),
    getItemURLPDF: flow(function* getItemURLPDF(deptId: string, formId: string, itemId: string) {
      store.setStatus("pending");
      const response = yield store.api().itemPDFURL(deptId, formId, itemId);
      store.setStatus("done");
      if (response.status == 200) {
        const json = yield response.json();
        return `${customApp.baseUrl}api${json.response}`;
      }
      return undefined;
    }),
    removeFilePayload: flow(function* saveFileOfPayload(uri: string) {
      try {
        if (Platform.OS == "web") {
          const resp = store.api().deleteFile(uri);
        } else {
          yield FileSystem.deleteAsync(uri);
        }
      } catch (e) {
        console.error(e)
      }
    }),
    migrateOldDB: flow(function* migrateOldDB() {
      let migrateStatus = yield storage.getItem("migrate");
      if (migrateStatus && migrateStatus == "V3")
        return;
      let allKeys = yield OldStore.getAllKeys();
      let userWithItems = allKeys.filter(key => key.substring(0, 8) == "st-items" && key.substring(9).indexOf("-") == -1);
      for (let i_key in userWithItems) {
        let key = userWithItems[i_key];
        let _userId = key.substring(9);
        let items = yield OldStore.getItem(`items-${_userId}`);
        items = JSON.parse(items);
        for (let idx in items) {
          let item = { ...items[idx] }
          yield store.insertItem({
            _startedAt: new Date(item["_startedAt"]),
            payload: item.payload,
            _timeOffset: item["_timeOffset"],
            memberId: item.memberId,
            tenantId: item.tenantId,
            deptId: item.deptId,
            formId: item.formId,
            taskId: item.taskId,
            status: item.status,
            readOnly: item.readOnly,
            _rnd: item["_rnd"],
            id: item.id,
            closedAt: new Date(item.closedAt),
          });
        }
      }
      yield storage.setItem("migrate", "V3");
    }),
    addItemsWorkflow: flow(function* addItemsWorkflow(depts) {
      const downloadFiles = async (itemId, deptId, formId, payload) => {
        if (Platform.OS != "web") {
          for (const fieldId in payload) {
            if (["sign"].includes(payload[fieldId].type) && payload[fieldId].value) {
              const urlFile = payload[fieldId].value;
              const fileName: string = FileSystem.documentDirectory.concat(`PTW${itemId}_${urlFile.substring(urlFile.lastIndexOf("/") + 1)}`);
              if ((await FileSystem.getInfoAsync(fileName)).exists == false) {
                console.log(fileName, urlFile)
                await FileSystem.downloadAsync(urlFile, fileName);
              }
              payload[fieldId].value = fileName;
            } else if (["photo", "video", "docs"].includes(payload[fieldId].type) && payload[fieldId].value) {
              console.log(payload[fieldId].type, payload[fieldId]);
              if (Array.isArray(payload[fieldId].value))
                for (let i = 0; i < payload[fieldId].value.length; i++) {
                  const urlFile = payload[fieldId].value[i].uri;
                  const fileName: string = FileSystem.documentDirectory.concat(`PTW${itemId}_${urlFile.substring(urlFile.lastIndexOf("/") + 1)}`);
                  if ((await FileSystem.getInfoAsync(fileName)).exists == false) {
                    console.log(fileName, urlFile)
                    await FileSystem.downloadAsync(urlFile, fileName);
                  }
                  payload[fieldId].value[i].uri = fileName;
                }
            } else if (["simplelist"].includes(payload[fieldId].type) && payload[fieldId].value) {
              if (Array.isArray(payload[fieldId].value))
                for (let i = 0; i < payload[fieldId].value.length; i++) {
                  await downloadFiles(itemId, deptId, formId, payload[fieldId].value[i]);
                }
            }
          }
        }
      }
      let items = [];
      //Limpio todos lo que estan a modo informativo para que deje solo los que recibe del servidor
      const workflowList = depts.flatMap(d => d.forms.map(f => f.id));
      for (let idx_rm = (store.itemsByUsersDB.length - 1); idx_rm >= 0; idx_rm--) {
        if ((store.itemsByUsersDB[idx_rm].id != undefined || store.itemsByUsersDB[idx_rm].status == "sent") && workflowList.includes(store.itemsByUsersDB[idx_rm].formId))
          yield store.removeItem(idx_rm);
      }

      for (let deptIDX in depts) {
        for (let formIDX in depts[deptIDX].forms) {
          if (Array.isArray(depts[deptIDX].forms[formIDX].items) && depts[deptIDX].forms[formIDX].items.length > 0) {
            const loadInfoID = store.addLoadingInfo({ title: "Permisos ".concat(depts[deptIDX].forms[formIDX].name, " Cantidad ", depts[deptIDX].forms[formIDX].items.length), spinner: true })
            let form = depts[deptIDX].forms[formIDX];
            let steps = { members: [], checks: [], reject: undefined, approved: undefined, allowChange: [], lockUser: false };
            if (form.workflow) {
              steps.lockUser = form.workflow.lockUser == true;
              steps.allowChange = form.workflow.allowChange || [];
              steps.reject = form.workflow.reject;
              steps.approved = form.workflow.steps_approved ? parseInt(form.workflow.steps_approved) : undefined;
              for (const idx in form.workflow.steps) {
                steps.members.push(form.workflow.steps[idx].member);
                steps.checks.push(form.workflow.steps[idx].checkStepON);
              }
            } else {
              for (let metaI in form.metadata) {
                if (form.metadata[metaI].key == "PTW_MEMBERS")
                  steps.members = form.metadata[metaI].value.replace(/ /g, "").split(",");
                if (form.metadata[metaI].key == "PTW_CHECKS")
                  steps.checks = form.metadata[metaI].value.replace(/ /g, "").split(",");
                if (form.metadata[metaI].key == "PTW_REJECT")
                  steps.reject = form.metadata[metaI].value.replace(/ /g, "");
                if (form.metadata[metaI].key == "PTW_STEPAPPROVED")
                  steps.approved = parseInt(form.metadata[metaI].value);
                if (form.metadata[metaI].key == "PTW_ALLOWCHANGE")
                  steps.allowChange = form.metadata[metaI].value.replace(/ /g, "").split(",");
                if (form.metadata[metaI].key == "PTW_LOCKUSER")
                  steps.lockUser = form.metadata[metaI].value && form.metadata[metaI].value != "0";
              }
            }
            items = [].concat(form.items)

            for (let idx in items) {
              let item = { ...items[idx] }              
              item.membersId = item.membersId || [item.memberId];
              //borro los registros previos de un mismo permiso y solo dejo el ultimo estado
              for (let idx_rm = 0; idx_rm < store.itemsByUsersDB.length; idx_rm++) {
                if (store.itemsByUsersDB[idx_rm].id && (store.itemsByUsersDB[idx_rm].id == item.id || store.itemsByUsersDB[idx_rm].id == item.prevId)) {
                  yield store.removeItem(idx_rm);
                }
              }

              let _status = "sent";
              let _statusPTW = "inprogress" //Default porque si no aparezco es que soy el creador
              let pendingStep = -1; //Es el paso donde esta
              let lockedUser = steps.lockUser ? item.membersId.includes(store.parent.authenticationStore.userIdLogged) : false;
              let stepStartToClose = (steps.approved ?? steps.checks.length);
              if (item.payload[steps.reject]?.value == true) {
                _statusPTW = "reject"
              } else if (item.payload[steps.checks[steps.checks.length - 1]]?.value == true) {
                _statusPTW = "closed"
              } else {
                try {
                  for (let stepI = 0; stepI < steps.checks.length; stepI++) {
                    console.log(idx, " Item", item.id, "step ", stepI, "fecha", item._createdAt, pendingStep, _statusPTW);
                    if (pendingStep == -1 && item.payload[steps.checks[stepI]].value == undefined)
                      pendingStep = stepI;
                    let el = item.payload[steps.members[stepI].trim()]


                    if ((!lockedUser || stepI >= stepStartToClose) && pendingStep > -1 && _statusPTW == "inprogress") {
                      let list = el.value.replace(/\*/g, "");
                      if (list.indexOf("|") > 0)
                        list = list.substring(0, list.indexOf("|"));
                      list = list.replace(/\|/g, "");
                      list = list.split(",");
                      if (list.includes(store.parent.authenticationStore.userIdLogged)
                        || list.includes(store.parent.authenticationStore.userInfo.user.email))
                        _statusPTW = pendingStep == stepI ? "pending" : "waiting"
                    }
                  }
                  if (pendingStep == -1)
                    _statusPTW = "closed";
                } catch (e) {
                  pendingStep = -1;
                  _statusPTW = "error"
                  console.log("error WORKFLOW")
                }
                console.log(idx, " Item", item.id, "step END", "fecha", item._createdAt, pendingStep, _statusPTW);
                if (pendingStep > -1 && _statusPTW == "pending") {
                  item.membersId = item.membersId || [];
                  item.membersId.push(store.parent.authenticationStore.userIdLogged);

                  item.payload[steps.checks[pendingStep]].value = true;
                  for (const fieldId of Object.keys(item.payload)) {
                    if (item.payload[fieldId].type == "autonumeric")
                      item.payload[fieldId].type = "number";
                    if (item.payload[fieldId].inactiveGroup != true && (steps.approved <= (pendingStep + 1) || !steps.allowChange.includes(fieldId)))
                      item.payload[fieldId].readOnly = true;
                  }

                  //El rechazar puede hacerse hasta la aprobacion, luego queda bloqueado
                  if (steps.approved && steps.reject && steps.approved >= (pendingStep + 1) && item.payload[steps.reject])
                    item.payload[steps.reject].readOnly = false;

                  for (let i = 0; i <= pendingStep; i++) {
                    item.payload[steps.members[i].trim()].readOnly = true;
                  }
                  _status = "draft";
                  //item.memberId = store.parent.authenticationStore.userIdLogged;
                  item.prevId = item.id;
                }
              }

              if (_status == "draft" && Platform.OS != "web") {
                downloadFiles(item.id, item.deptId, item.formId, item.payload);
              }
              if ((steps.approved ?? steps.checks.length) <= pendingStep)
                _statusPTW = "finished";
              if (!(Platform.OS != "web" && items.length > 200 && _status == "sent"))
                store.insertItem({
                  _startedAt: new Date(item["_startedAt"]),
                  payload: item.payload,
                  _timeOffset: item["_timeOffset"],
                  memberId: store.parent.authenticationStore.userIdLogged,
                  membersId: item.membersId,
                  formType: item.formType,
                  tenantId: item.tenantId,
                  deptId: item.deptId,
                  formId: item.formId,
                  taskId: item.taskId,
                  status: _status,
                  statusPTW: _statusPTW,
                  stepNumber: pendingStep,
                  readOnly: item.readOnly,
                  _rnd: item["_rnd"],
                  prevId: item.prevId,
                  id: item.id,
                  closedAt: new Date(item.closedAt),
                });
            }
          }
          delete depts[deptIDX].forms[formIDX].items;
        }

      }


    }),
  }))

export interface ItemsStore extends Instance<typeof ItemsStoreModel> { }
export interface ItemsStoreSnapshotOut extends SnapshotOut<typeof ItemsStoreModel> { }
export interface ItemsStoreSnapshotIn extends SnapshotIn<typeof ItemsStoreModel> { }
export const createItemsStoreDefaultModel = () => types.optional(ItemsStoreModel, {})

